import React, { useState } from "react";
import routes from "../routes";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ThemeModeType } from "../config/type";
import { GITBOOK_LINK } from "../config/constant";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

interface AppHeaderProps {
  toggleTheme: () => void;
}

const appRouteList = [
  {
    label: routes.home.name,
    href: routes.home.path,
    walletStatus: routes.home.walletStatus,
    loginStatus: routes.home.loginStatus,
    adminRoute: routes.home.adminRoute,
  },
  {
    label: routes.dao.name,
    href: routes.dao.path,
    walletStatus: routes.dao.walletStatus,
    loginStatus: routes.dao.loginStatus,
    adminRoute: routes.dao.adminRoute,
  },
  {
    label: routes.ecosystem.name,
    href: routes.ecosystem.path,
    walletStatus: routes.ecosystem.walletStatus,
    loginStatus: routes.ecosystem.loginStatus,
    adminRoute: routes.ecosystem.adminRoute,
  },
  {
    label: routes.utilities.name,
    href: routes.utilities.path,
    walletStatus: routes.utilities.walletStatus,
    loginStatus: routes.utilities.loginStatus,
    adminRoute: routes.utilities.adminRoute,
  },
];

const AppHeader: React.FC<AppHeaderProps> = ({ toggleTheme }) => {
  const [isCollapsed, setCollapse] = useState<boolean>(true);
  const location = useLocation();
  const selectedTheme = useSelector((state: RootState) => state.theme as ThemeModeType);
  const progress = useSelector((state: RootState) => state.progress);

  const handleNavTabs = () => {
    window.screen.width < 1200 && setCollapse(true);
  };

  return (
    <nav
      className={`navbar navbar-expand-xl navbar-dark px-2 bg-green pb-0 ${
        progress.max !== progress.progress ? "" : "fixed-top"
      }`}
    >
      <div className={`container-fluid`}>
        <a className="navbar-brand" href="/">
          <img className="logo-img" alt="420 Club" src="/assets/420logoNBG0-cropped.png" />
        </a>
        {isCollapsed && (
          <button type="button" className="btn px-3 py-2 nav-link d-none d-xl-grid " onClick={toggleTheme}>
            {selectedTheme === "light" && <i className={`fa-solid fa-lg fa-sun text-warning`} />}
            {selectedTheme === "dark" && <i className={`fa-solid fa-lg fa-moon text-white`} />}
          </button>
        )}
        <button
          onClick={() => setCollapse(!isCollapsed)}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={"justify-content-end navbar-collapse align-items-center " + (isCollapsed ? `collapse` : ``)}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto my-2 text-center">
            {appRouteList.map((header, idx) => (
              <li
                className={`nav-item text-white mx-auto mx-lg-0 d-flex justify-content-center align-items-center`}
                key={idx}
              >
                <Link
                  className={`px-3 nav-link text-white mx-auto ${
                    location.pathname === header.href ? "fw-bold border-bottom border-3 border-warning" : "fw-bolder"
                  }`}
                  to={header.href}
                  onClick={handleNavTabs}
                >
                  {header.label}
                </Link>
              </li>
            ))}
            {/* <li className="nav-item text-white mx-auto mx-lg-0 d-flex justify-content-center align-items-center">
              <a href={GITBOOK_LINK} className=" text-decoration-none dropdown-item" target="_blank" rel="noreferrer">
                DOCS
                <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
              </a>
            </li> */}
            {/* <li className={`nav-item opacity-100 d-flex justify-content-center align-items-center`}>
              <div className="dropdown">
                <span
                  className="nav-link dropdown-toggle fw-bolder"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  MORE
                </span>
                <ul className={`dropdown-menu text-center my-2 bg-light p-0 `} aria-labelledby="navbarDropdown">
                  {appRouteList.slice(5, 8).map((header, idx) => (
                    <li key={idx} className={` py-1`}>
                      <Link
                        to={header.href}
                        className={`dropdown-item ${header.href === location.pathname ? "active-dropdown" : ""}`}
                      >
                        {header.label}
                      </Link>
                    </li>
                  ))}
                  <li className="py-2 text-dark fw-medium">
                    <a
                      href={GITBOOK_LINK}
                      className=" text-decoration-none dropdown-item"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GITBOOK
                      <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
            {!isCollapsed && (
              <li className="nav-item text-center d-xl-none">
                <button type="button" className="btn px-3 py-2 mx-auto mx-xl-0 nav-link " onClick={toggleTheme}>
                  {selectedTheme === "light" && <i className={`fa-solid fa-lg fa-sun text-warning`} />}
                  {selectedTheme === "dark" && <i className={`fa-solid fa-lg fa-moon text-white`} />}
                </button>
              </li>
            )}
            <li className="nav-item ps-3 mx-auto d-flex align-items-center">
              <WalletMultiButton className="btn bg-grad py-2 px-4" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AppHeader;
