export const SOLANA_RPC_POOL_URL: string = process.env.REACT_APP_SOLANA_RPC_HOST;

export const DISCORD_LINK: string = "https://discord.gg/420club";
export const TWITTER_LINK: string = "https://twitter.com/420ClubNFT";

//DAO
export const CLUB_420_LINK: string = "https://magiceden.io/marketplace/420chs";
export const SKUNKS_BUY_LINK: string = "";
export const SKUNKS_MINT_LINK: string = "";

// MINT LINKS
export const MINT_420_CLUB_BLUNTS: string = "https://bluntsmint.420club.net";

//Others
export const THEME_MODE: string = "theme";
export const GITBOOK_LINK: string = "https://docs.420club.net/";

export const BLOCKCHAIN_NETWORK: string = "13knf0234";
export const LOGIN_TOKEN: string = "08U23ORKNMFW823";

export const BOOK_A_CALL: string = "https://tr.ee/yZUwLYITUc";
