import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../config/server";
import { ResponseDataType } from "../../config/type";

export const loginUser = createAsyncThunk<ResponseDataType, any, { rejectValue: ResponseDataType }>(
  "users/login",
  async (formData: any, { rejectWithValue }) => {
    try {
      return await server.post("users/login", formData).then((res) => res.data);
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          data: null,
          message: "Oops something went wrong",
          success: false,
        });
      }
    }
  }
);

export const authenticateUser = createAsyncThunk<ResponseDataType, String, { rejectValue: ResponseDataType }>(
  "users/authenticate",
  async (pubkey, { rejectWithValue }) => {
    try {
      return await server.get("/users/authenticate", { params: { pubkey } }).then((res) => res.data);
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          data: null,
          message: "Oops something went wrong",
          success: false,
        });
      }
    }
  }
);
