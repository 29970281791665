import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type ProgressProps = {
  progress: number;
  max: number;
  message: string;
};

const initialState: ProgressProps = {
  progress: 0,
  max: 0,
  message: "",
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<ProgressProps>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProgress } = progressSlice.actions;

export default progressSlice.reducer;
