import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BlockchainNetwork } from "../helpers/hashlips-generator/type";
import { BLOCKCHAIN_NETWORK } from "../config/constant";
export const blockchainNetworkSlice = createSlice({
  name: "blockchainNetworkSlice",
  initialState: localStorage.getItem(BLOCKCHAIN_NETWORK) || "solana",
  reducers: {
    setBlockchainNetwork: (_, action: PayloadAction<BlockchainNetwork>) => {
      localStorage.setItem(BLOCKCHAIN_NETWORK, action.payload);
      return action.payload;
    },
  },
});
export const { setBlockchainNetwork } = blockchainNetworkSlice.actions;
export default blockchainNetworkSlice.reducer;
