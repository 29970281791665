import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authenticateUser, loginUser } from "./async-actions/userAction";
import { LOGIN_TOKEN } from "../config/constant";
import { toast } from "react-toastify";

type UserProps = {
  token?: string;
  isAuthenticated: boolean;
  pubkey?: string;
  isAdmin?: boolean;
};

const initialState: UserProps = {
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<UserProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const user: any = payload.data.user;

        sessionStorage.setItem(LOGIN_TOKEN, user.token);

        toast.success(payload.message);
        state.token = user.token;
        state.pubkey = user.pubkey;
        state.isAdmin = user.isAdmin;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state = { isAuthenticated: false };
        toast.warning(payload?.message);
      });

    builder
      .addCase(authenticateUser.fulfilled, (state, { payload }) => {
        const user: any = payload.data.user;
        state.token = user.token;
        state.pubkey = user.pubkey;
        state.isAdmin = user.isAdmin;
        state.isAuthenticated = true;
      })
      .addCase(authenticateUser.rejected, (state, { payload }) => {
        state = { isAuthenticated: false };
      });
  },
});

export const { setUser } = userSlice.actions;

//Action creators are generated for each case reducer function
export default userSlice.reducer;
