import React from "react";
import { Link, useNavigate } from "react-router-dom";
import routes from "../routes";

const Error404: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="error-container">
      <div className="container vh-100">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div className="error-text-container text-center">
            <i className="fa-solid fa-triangle-exclamation text-light fs-1 my-2"></i>
            <p className="my-2 fs-1 h1 fw-bold text-light">404 Error</p>
            <p className="text-warning fs-4 fst-italic">Oops! The page you're looking for is missing.</p>
            <div className="popular-links-container mt-5 pt-5">
              <p className="text-light">Popular links you may be looking for?</p>
              <ul className="list-group list-group-horizontal justify-content-center bg-transparent ">
                <li className="list-group-item bg-warning">
                  <Link to={routes.bots.path} className="text-decoration-none text-dark">
                    Bots
                  </Link>
                </li>
                <li className="list-group-item bg-warning">
                  <Link to={routes.services.path} className="text-decoration-none text-dark">
                    Services
                  </Link>
                </li>
                <li className="list-group-item bg-warning">
                  <Link to={routes.tools.path} className="text-decoration-none text-dark">
                    Tools
                  </Link>
                </li>
              </ul>
              <button onClick={goBack} type="button" className="btn mt-4 py-2 text-light">
                <i className="fa-solid fa-arrow-left me-1"></i>Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
