import React, { useState } from "react";

const MyProgressBar: React.FC<{
  max: number;
  value: number;
}> = ({ max, value }) => {
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={max}
        style={{
          width: `${Math.round((100 * value) / max)}%`,
        }}
      ></div>
    </div>
  );
};

const useProgressBar = ({ max }) => {
  const [progress, setProgress] = useState(0);

  return {
    MyProgressBar: () => <MyProgressBar max={max} value={progress} />,
    setProgress,
  };
};

export { useProgressBar };
