import axios from "axios";
import { LOGIN_TOKEN } from "./constant";

const server = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${sessionStorage.getItem(LOGIN_TOKEN)}`,
  },
});

server.interceptors.request.use((config) => {
  const token = sessionStorage.getItem(LOGIN_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default server;
