import React from "react";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import { HashRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Loading from "./components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { ThemeModeType } from "./config/type";
import { setTheme } from "./redux/themeSlice";
import { ToastContainer } from "react-toastify";

function App() {
  const isLoading = useSelector((state: RootState) => state.isLoading);
  const progress = useSelector((state: RootState) => state.progress);
  const selectedTheme: ThemeModeType = useSelector((state: RootState) => state.theme);

  const dispatch = useDispatch();

  const toggleTheme = () => {
    let theme: ThemeModeType = null;

    if (selectedTheme === "light") {
      theme = "dark";
    } else {
      theme = "light";
    }

    dispatch(setTheme(theme));
  };

  return (
    <HashRouter future={{ v7_startTransition: true }}>
      <ToastContainer />
      <div data-bs-theme={selectedTheme}>
        {(isLoading || progress.max !== progress.progress) && <Loading {...progress} />}
        <div className="main">
          <AppHeader toggleTheme={toggleTheme} />
          <AppRoutes />
          <AppFooter />
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
