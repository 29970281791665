import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import routes from "./routes";
import Loading from "./components/Loading";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAppDispatch } from "./redux/store";
import { setUser } from "./redux/userSlice";
import { authenticateUser } from "./redux/async-actions/userAction";
import Error404 from "./components/Error404";

const AppRoutes: React.FC = () => {
  const { connected, publicKey } = useWallet();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUser({ isAuthenticated: false }));
    if (connected && publicKey) {
      // dispatch(authenticateUser(publicKey.toBase58()));
    }
  }, [connected, publicKey, dispatch]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          {Object.values(routes).map((route, idx) => (
            <Route key={idx} element={<route.component />} path={route.path} />
          ))}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
