import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loadingSlice from "./loadingSlice";
import progressSlice from "./progressSlice";
import { useDispatch } from "react-redux";
import themeSlice from "./themeSlice";
import blockchainNetworkSlice from "./blockchainNetworkSlice";
import userSlice from "./userSlice";

export const store = configureStore({
  reducer: combineReducers({
    isLoading: loadingSlice,
    progress: progressSlice,
    theme: themeSlice,
    blockchainNetwork: blockchainNetworkSlice,
    user: userSlice,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
