import React from "react";
import { MyRouteProps } from "./config/type";

const Home = React.lazy(() => import("./views/Home/Home"));
const DAO = React.lazy(() => import("./views/DAO/Dao"));
const Ecosystem = React.lazy(() => import("./views/Ecosystem/Ecosystem"));
const Utilities = React.lazy(() => import("./views/Utilities/Utilities"));
const HotBoxHome = React.lazy(() => import("./views/HotBox/HotBoxHome"));

const routes: {
  [key: string]: MyRouteProps;
} = {
  home: { name: "HOME", component: Home, path: "/", exact: true, loginStatus: "not-required", walletStatus: "not-required", adminRoute: false },
  utilities: {
    name: "UTILITIES",
    component: Utilities,
    exact: true,
    path: "/utilities",
    adminRoute: false,
    loginStatus: "not-required",
    walletStatus: "not-required",
  },
  hotBox: {
    name: "Hot Box",
    component: HotBoxHome,
    exact: true,
    path: "/hot-box",
    adminRoute: false,
    loginStatus: "not-required",
    walletStatus: "not-required",
  },
  dao: { name: "DAO", component: DAO, path: "/dao", exact: true, loginStatus: "not-required", walletStatus: "not-required", adminRoute: false },
  ecosystem: { name: "ECOSYSTEM", component: Ecosystem, path: "/ecosystem", exact: true, loginStatus: "not-required", walletStatus: "not-required", adminRoute: false },
};

export default routes;
