import React, { useEffect } from "react";
import { useProgressBar } from "./MyProgressBar";

const Loading: React.FC<{
  progress?: number;
  max?: number;
  message?: string;
}> = ({ progress, max, message }) => {
  const { MyProgressBar, setProgress } = useProgressBar({ max });

  useEffect(() => {
    setProgress(progress);
  }, [progress, setProgress]);

  return (
    <div className="loading-container">
      <div className="container">
        <div className="top"></div>
        <div className="inner-oval">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
        </div>
        {progress !== max && (
          <>
            <MyProgressBar />
            <h3 className=" text-white fw-lighter py-2">{message}</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Loading;
